import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import './NavBarStyle.css';
import israel from '../../images/israel.svg';
import england from '../../images/england.svg';

const NavLinks = () => {
  const [t, i18n] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(true);
  const [isRTL, setIsRTL] = useState(localStorage.getItem('language') === 'he');

  useEffect(() => {
    const lang = isRTL ? 'he' : 'en';
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);

    if (isRTL) {
      document.body.classList.remove('dir-ltr');
      document.body.classList.add('dir-rtl');
    } else {
      document.body.classList.remove('dir-rtl');
      document.body.classList.add('dir-ltr');
    }

    setIsLoading(false);
  }, [isRTL, i18n]);

  const handleToggle = () => {
    setIsRTL(prevIsRTL => !prevIsRTL);
  };

  if (isLoading) {
    return <div></div>;
  }

  return (
    <>
      <div className="nav-container">
        <div className="navigation-container">
          <HashLink className="nav-link" smooth to="/aboutGrovv">
            {t("header.About")}
          </HashLink>
          <HashLink className="nav-link" smooth to="/services">
            {t("header.Services")}
          </HashLink>
          <HashLink className="nav-link" smooth to="/competence">
            {t("header.Competence")}
          </HashLink>
          <HashLink className="nav-link" to="/partners">
            {t("header.Partners")}
          </HashLink>
          <HashLink className="nav-link" to="/customers">
            {t("header.Costumers")}
          </HashLink>
          <HashLink className="nav-link" to="/contact">
            {t("header.Contact")}
          </HashLink>
          <HashLink className="nav-link" to="/careers">
            {t("header.Careers")}
          </HashLink>
        </div>
        <div className={`flags-container ${isRTL ? 'rtl' : 'ltr'}`}>
          <img
            alt="Israeli flag"
            src={israel}
            className="flag"
            onClick={() => setIsRTL(true)}
          />
          <img
            alt="UK flag"
            src={england}
            className="flag"
            onClick={() => setIsRTL(false)}
          />
        </div>
      </div>
    </>
  );
};

export default NavLinks;
