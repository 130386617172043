import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import Customers from './pages/Customers';
import Careers from './pages/Careers';
import Partners from './pages/Partners';
import Competence from './pages/Competence';
import AboutGrovv from './pages/AboutGrovv';
import Roman from './pages/Partners';
import JobDescription from './pages/JobDescription';
import JobDescription2 from './pages/JobDescription2';
import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import Services from './pages/Services';
import CookieConsent from 'react-cookie-consent';


function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Grovv");
  

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="/Customers" element={<Customers />} /> 
            <Route path="/careers" element={<Careers />} /> 
            <Route path="/partners" element={<Partners />} /> 
            <Route path="/Competence" element={<Competence />} /> 
            <Route path="/About" element={<AboutGrovv />} /> 
            <Route path="/services" element={<Services />} />
            {/* <Route path="/roman" element={<Roman />} />  */}
            <Route path="/jobPosition" element={<JobDescription />} />
            <Route path="/jobPosition2" element={<JobDescription2 />} /> 
            <Route path="/aboutGrovv" element={<AboutGrovv />} /> 
          </Routes>
        </ScrollToTop>
      </Router>
      <CookieConsent
      debug={true}
      buttonText="Accept All"
      buttonStyle={{ background: "#64cd27", color: "#000", margin: "0 10px" }} // Accept button background and text color
      style={{ background: "#000", color: "#fff" }} // Component background and text color
      enableDeclineButton
      declineButtonText="Reject"
      declineButtonStyle={{ background: "#ffcccb", color: "#000" }} // Reject button background and text color
      flipButtons
    >

      We utilize cookies to provide you with tailored content and ensure an optimal user experience. Cookies also assist us in analyzing traffic and enhancing our website. By clicking the "Accept All" button, you consent to our use of cookies.
    </CookieConsent>
    </>
  
  );
}


export default App;
